/* eslint-disable */
<template>
  <!-- eslint-disable max-len -->
  <fragment>
    <section class="content-wrapper">
      <nav class="breadcrumb-show">
        <div class="container">
          <a href="javascript:(0)" class="breadcrumb-show__item" aria-current="page"> Cart</a>
          <p class="breadcrumb-show__description">
            Print your desired look on ready-made shirts with various sizes and colors.
          </p>
        </div>
      </nav>
      <nav aria-label="breadcrumb " class="d-none d-md-block">
        <div class="container">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Shop</a></li>
            <li class="breadcrumb-item"><a href="#">Cart</a></li>
            <li class="breadcrumb-item active" aria-current="page">Payment</li>
          </ol>
        </div>
      </nav>
      <div class="cart cart-checkout">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
             <div class="order-notify">
                <div class="order-notify__title mb-3">Thank you!</div>
                <div class="order-notify__confirm active">
                  <div class="py-3 px-2 mb-3">
                    Your order has been received and will be delivered to you as
                    soon as possible.
                  </div>
                  <div class="code-order">
                    <p class="">Number order: </p>
                    <p class="code-order__code-number">{{numberOrder}}</p>
                  </div>
                </div>
                <a class="btn btn-primary-2 w-100" href="/">Back to home</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>
<script>
/* eslint-disable */
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      numberOrder: ''
    };
  },
  async mounted() {
    let query = this.$route.query;
    if (query.paymentId) {
      this.numberOrder = query.paymentId.replace('PAYID-', '');
      const params = {
        paymentId: query.paymentId,
        token: query.token,
        payerId: query.PayerID
      };
      const url = await this.processPayment(params).finally(() => this.loading = false)
                            .catch((error) => {
                              console.log(error);
                            });
    }
  },
  methods: {
    ...mapActions({
      processPayment: "cart/processPayment",
    }),
  },
  computed: {},
};
</script>
